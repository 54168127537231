// frontend/src/pages/SubscriptionSuccess.js
import React from "react";
import { useNavigate } from "react-router-dom";

function SubscriptionSuccess() {
  const navigate = useNavigate();

  const handleGoToChat = () => {
    navigate("/chat");
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-background dark:bg-background-dark text-textPrimary dark:text-textPrimary-dark">
      <h1 className="text-3xl font-bold mb-4">ご購入ありがとうございます！</h1>
      <p className="text-lg mb-8">
        プレミアムプランの購入が完了しました。引き続き高性能なAIモデルをご利用いただけます。
      </p>
      <button
        onClick={handleGoToChat}
        className="px-6 py-3 bg-blue-600 text-white rounded hover:bg-blue-700"
      >
        チャット画面に戻る
      </button>
    </div>
  );
}

export default SubscriptionSuccess;
