// Layout.js
import React, { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import axios from "axios";
import SideMenu from "./SideMenu";
import Header from "./Header";
import useChat from "../../hooks/useChat";
import TaskList from "./TaskList"; // TaskListをインポート

function Layout({ handleLogout, email, theme, setTheme }) {
  // ウィンドウ幅を取得
  const initialWindowWidth = window.innerWidth;
  const [isTaskbarOpen, setIsTaskbarOpen] = useState(initialWindowWidth >= 768);
  // 初期値を画面幅に応じて設定
  const [isSidebarOpen, setIsSidebarOpen] = useState(initialWindowWidth >= 768);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [aiModels, setAiModels] = useState([]); // 追加
  const chatContext = useChat();
  const [taskbarWidth, setTaskbarWidth] = useState(320); // 初期幅を256pxに設定
  const [isResizing, setIsResizing] = useState(false);
  const sidebarRef = useRef(null);
  const [isPremium, setIsPremium] = useState(false); // 追加

  const {
    messages,
    pastChats,
    activeChatId,
    currentChatTitle,
    startNewChat,
    deleteChat,
    renameChat,
  } = chatContext;

  const navigate = useNavigate();

  // ウィンドウサイズの変更を監視
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // ユーザーユーザー設定の取得
  useEffect(() => {
    axios
      .get("/user/profile")
      .then((res) => {
        setTheme(res.data.theme || "system");
        if (res.data.profileImageUrl) {
          setProfileImageUrl(res.data.profileImageUrl);
        }
        if (res.data.aiModels) {
          setAiModels(res.data.aiModels);
        }
        if (res.data.isPremium !== undefined) {
          setIsPremium(res.data.isPremium); // 追加
        }
      })
      .catch((err) => {
        console.error("Error fetching user profile:", err);
      });
  }, [setTheme]);

  // ドラッグハンドルのイベントハンドラ
  const handleMouseDown = (e) => {
    setIsResizing(true);
    e.preventDefault();
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isResizing) {
        const newWidth = window.innerWidth - e.clientX;
        if (newWidth >= 256) {
          setTaskbarWidth(newWidth);
        }
      }
    };

    const handleMouseUp = () => {
      if (isResizing) {
        setIsResizing(false);
      }
    };

    if (isResizing) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    } else {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isResizing]);

  return (
    <div className="h-screen text-sm overflow-hidden flex bg-background dark:bg-background-dark">
      {/* サイドメニュー */}
      <SideMenu
        ref={sidebarRef}
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        handleLogout={handleLogout}
        email={email}
        theme={theme}
        setTheme={setTheme}
        profileImageUrl={profileImageUrl}
        pastChats={pastChats}
        activeChatId={activeChatId}
        onChatSelect={(chatId) => {
          navigate(`/chat/${chatId}`);
        }}
        onChatDelete={deleteChat}
        onChatRename={renameChat}
        startNewChat={startNewChat}
        messages={messages}
        setIsTaskbarOpen={setIsTaskbarOpen}
        aiModels={aiModels} // 追加
        setAiModels={setAiModels} // 追加
        isPremium={isPremium}
      />
      {/* メインコンテンツ */}
      <div
        className="flex flex-col flex-1 min-h-0"
        style={{
          marginLeft: isSidebarOpen ? "16rem" : "0",
          marginRight: isTaskbarOpen ? `${taskbarWidth}px` : "0",
          zIndex: 0,
        }}
        onClick={(e) => {
          if (window.innerWidth < 768) {
            if (
              isSidebarOpen &&
              sidebarRef.current &&
              !sidebarRef.current.contains(e.target)
            ) {
              setIsSidebarOpen(false);
            }
            // タスクバーに対しても同様の処理を追加できます
          }
        }}
      >
        {/* ヘッダー */}
        <Header
          currentChatTitle={currentChatTitle}
          setIsSidebarOpen={setIsSidebarOpen}
          isSidebarOpen={isSidebarOpen}
          setIsTaskbarOpen={setIsTaskbarOpen}
          isTaskbarOpen={isTaskbarOpen}
        />
        {/* 子コンポーネントにコンテキストを渡す */}
        <div className="flex flex-col flex-1 min-h-0 overflow-auto">
          <Outlet context={{ ...chatContext, aiModels }} />
        </div>
      </div>
      {/* ドラッグハンドル */}
      {isTaskbarOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            right: `${taskbarWidth}px`,
            width: "5px",
            height: "100%",
            cursor: "col-resize",
            zIndex: 1000,
          }}
          onMouseDown={handleMouseDown}
        />
      )}
      {/* タスクリスト */}
      <TaskList
        isTaskbarOpen={isTaskbarOpen}
        setIsTaskbarOpen={setIsTaskbarOpen}
        activeChatId={chatContext.activeChatId}
        taskbarWidth={taskbarWidth}
      />
    </div>
  );
}

export default Layout;
