// MessageBubble.js

import React, { useState, useEffect, useRef, memo } from "react";
import {
  PencilIcon,
  ArrowPathIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"; // GFM (GitHub Flavored Markdown)をサポート
import rehypeRaw from "rehype-raw"; // HTMLをパース
import CodeBlock from "./CodeBlock";
import ImageWithPopup from "./ImageWithPopup";
import EditMessageForm from "./EditMessageForm";
import modelIcons from "../../assets/modelIcons";

const API_URL = process.env.REACT_APP_API_URL;
const BASE_URL = API_URL.replace("/api", "");

const MessageBubble = ({
  messageId,
  messages,
  messageVersionState,
  setMessageVersionState,
  editingId,
  setEditingId,
  updateMessage,
  isUpdating,
  setError,
  retryMessage,
  setActiveMessageId,
  depth,
  navigateSiblingAtDepth,
  selectedModel,
  setSelectedModel,
  isResponding,
  messagesContainerRef, // 追加
  isLastMessage, // 追加
  isUserAtBottom, // 追加
  aiModels,
}) => {
  const currentVersionId = messageVersionState[messageId] || messageId;
  const currentMessage = messages.find((msg) => msg._id === currentVersionId);

  const [aiResponse, setAiResponse] = useState(
    currentMessage?.aiResponse || ""
  );
  const aiResponseRef = useRef(aiResponse);

  // currentMessageのaiResponseを抽出
  const aiResponseCurrent = currentMessage?.aiResponse;

  useEffect(() => {
    if (currentMessage && currentMessage.aiResponse !== aiResponseRef.current) {
      setAiResponse(currentMessage.aiResponse || "");
      aiResponseRef.current = currentMessage.aiResponse;
    }
  }, [aiResponseCurrent, currentMessage]);

  // 自動スクロール用のuseEffectを修正
  useEffect(() => {
    if (
      isLastMessage &&
      messagesContainerRef &&
      messagesContainerRef.current &&
      isUserAtBottom
    ) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [aiResponse, isLastMessage, messagesContainerRef, isUserAtBottom]);

  if (!currentMessage) {
    return null;
  }

  const parentMessageId = currentMessage.parentId;

  const siblings = messages
    .filter((m) => m.parentId === parentMessageId)
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  const currentIndex = siblings.findIndex((m) => m._id === currentVersionId);
  const totalVersions = siblings.length;

  const isEditing = editingId === currentMessage._id;

  const handlePreviousVersion = () => {
    navigateSiblingAtDepth(depth, -1);
  };

  const handleNextVersion = () => {
    navigateSiblingAtDepth(depth, 1);
  };

  const model = currentMessage.model || "unknown";

  // 音声ファイルの拡張子を定義
  const audioExtensions = [
    ".mp3",
    ".mp4",
    ".mpeg",
    ".mpga",
    ".m4a",
    ".wav",
    ".webm",
  ];

  // 音声ファイルかどうかを判定する関数
  const isAudioFile = (file) => {
    return audioExtensions.some((ext) => file.filename.endsWith(ext));
  };

  // ファイル拡張子を取得する関数
  const getFileExtension = (file) => {
    return file.filename.split(".").pop().toUpperCase();
  };

  // モデルに応じたアイコンを取得
  const iconSrc = modelIcons[model];
  const renderContent = () => {
    if (currentMessage.model === "dall-e-3") {
      return (
        <img
          src={aiResponse.replace(/!\[DALL-E Generated Image\]\((.*?)\)/, "$1")}
          alt="Generated By DALL-E"
          className="max-w-full h-auto rounded-lg shadow-lg"
        />
      );
    } else if (currentMessage.model === "stable-image-ultra") {
      return (
        <img
          src={aiResponse}
          alt="Generated By Stable Diffusion Ultra"
          className="max-w-full h-auto rounded-lg shadow-lg"
        />
      );
    } else {
      return (
        <ReactMarkdown
          className="prose max-w-full dark:prose-invert"
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={{
            pre({ node, children, ...props }) {
              return <>{children}</>;
            },
            code({ node, inline, className, children, ...props }) {
              const hasLang = /language-(\w+)/.exec(className || "");
              if (inline) {
                return (
                  <code
                    className={className}
                    style={{ fontSize: "1rem" }}
                    {...props}
                  >
                    {children}
                  </code>
                );
              } else if (hasLang) {
                return (
                  <CodeBlock
                    language={hasLang[1]}
                    value={String(children).replace(/\n$/, "")}
                  />
                );
              } else {
                return (
                  <code
                    className={className}
                    style={{ fontSize: "1rem" }}
                    {...props}
                  >
                    {children}
                  </code>
                );
              }
            },
            img({ node, ...props }) {
              return <img {...props} alt={props.alt} />;
            },
          }}
        >
          {aiResponse}
        </ReactMarkdown>
      );
    }
  };

  return (
    <div key={currentMessage._id} className="mb-4 min-w-0">
      {/* ユーザーメッセージの表示 */}
      <div
        className="px-5 py-4 rounded-2xl shadow-sm bg-messageUser dark:bg-messageUser-dark max-w-full md:max-w-2xl mx-auto text-textPrimary dark:text-textPrimary-dark text-base leading-6"
        style={{
          marginLeft: "1em",
          marginRight: "0",
          marginTop: "0",
          marginBottom: "0",
        }}
      >
        {isEditing ? (
          <EditMessageForm
            currentMessage={currentMessage}
            setEditingId={setEditingId}
            updateMessage={updateMessage}
            setError={setError}
            isResponding={isResponding}
            selectedModel={selectedModel}
            setSelectedModel={setSelectedModel}
            aiModels={aiModels}
          />
        ) : (
          <div className="relative">
            <p className="whitespace-pre-wrap break-words">
              {currentMessage.content}
            </p>
            {currentMessage.files && currentMessage.files.length > 0 && (
              <div className="flex flex-wrap mt-2">
                {currentMessage.files.map((file, index) => (
                  <div key={index} className="relative m-1">
                    {file.mimetype ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                      <div className="w-20 h-20 bg-blue-100 flex items-center justify-center rounded">
                        <span className="text-blue-500 font-bold">DOCX</span>
                      </div>
                    ) : file.mimetype ===
                      "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                      <div className="w-20 h-20 bg-orange-100 flex items-center justify-center rounded">
                        <span className="text-orange-500 font-bold">PPTX</span>
                      </div>
                    ) : file.mimetype === "application/pdf" ? (
                      <div className="w-20 h-20 bg-red-100 flex items-center justify-center rounded">
                        <span className="text-red-500 font-bold">PDF</span>
                      </div>
                    ) : file.mimetype === "text/csv" ? (
                      <div className="w-20 h-20 bg-green-100 flex items-center justify-center rounded">
                        <span className="text-green-500 font-bold">CSV</span>
                      </div>
                    ) : isAudioFile(file) ? (
                      <div className="w-20 h-20 bg-blue-100 flex items-center justify-center rounded">
                        <span className="text-blue-500 font-bold">
                          {getFileExtension(file)}
                        </span>
                      </div>
                    ) : (
                      <ImageWithPopup
                        src={`${BASE_URL}${file.filepath}`}
                        alt={`Uploaded ${index}`}
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
            <button
              className="absolute bottom-0 right-0 text-gray-500 hover:text-gray-700"
              onClick={() => {
                setEditingId(currentMessage._id);
              }}
            >
              <PencilIcon className="h-4 w-4" />
            </button>
          </div>
        )}
      </div>
      {/* バージョン切り替えの矢印と番号 */}
      <div className="flex justify-end mt-1">
        <div className="flex items-center rounded-full px-2">
          <button
            className="p-1 rounded-full hover:bg-gray-200 dark:hover:bg-gray-600"
            onClick={handlePreviousVersion}
            disabled={currentIndex === 0}
          >
            <ChevronLeftIcon className="h-5 w-5 text-[#7d7d7d] dark:text-[#b4b4b4]" />
          </button>
          <span className="mx-1 text-sm text-[#7d7d7d] dark:text-[#b4b4b4]">
            {currentIndex + 1} / {totalVersions}
          </span>
          <button
            className="p-1 rounded-full hover:bg-gray-200 dark:hover:bg-gray-600"
            onClick={handleNextVersion}
            disabled={currentIndex === totalVersions - 1}
          >
            <ChevronRightIcon className="h-5 w-5 text-[#7d7d7d] dark:text-[#b4b4b4]" />
          </button>
        </div>
      </div>
      {/* AIの返信メッセージ */}
      {!aiResponse ? (
        <div
          className="rounded-xl mt-2 max-w-full md:max-w-2xl mx-auto text-textPrimary dark:text-textPrimary-dark overflow-x-auto w-full text-base leading-7"
          style={{ padding: "8px 16px", margin: "0" }}
        >
          <div className="flex items-center">
            <img src={iconSrc} alt={model} className="h-6 w-6 mr-2" />
            <span className="font-bold text-gray-700 dark:text-gray-300">
              Thinking...
            </span>
          </div>
        </div>
      ) : (
        aiResponse && (
          <div
            className="rounded-xl mt-2 max-w-full md:max-w-2xl mx-auto text-textPrimary dark:text-textPrimary-dark overflow-x-auto w-full text-base leading-7"
            style={{ padding: "8px 16px", margin: "0" }}
          >
            <div className="flex justify-between items-start mb-2">
              <div className="flex items-center">
                {currentMessage.model ? (
                  <>
                    <img src={iconSrc} alt={model} className="h-6 w-6 mr-2" />
                    <span className="font-bold text-textPrimary dark:text-textPrimary-dark">
                      {model}
                    </span>
                    <button
                      className="ml-2 text-gray-500 hover:text-gray-700"
                      onClick={() =>
                        retryMessage(
                          currentMessage._id,
                          currentMessage.model || selectedModel
                        )
                      }
                    >
                      <ArrowPathIcon className="h-4 w-4" />
                    </button>
                  </>
                ) : (
                  <span className="font-bold text-gray-700">Unknown Model</span>
                )}
              </div>
            </div>
            {renderContent()}
          </div>
        )
      )}
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  if (prevProps.messageId !== nextProps.messageId) {
    return false;
  }

  const isEditingPrev = prevProps.editingId === prevProps.messageId;
  const isEditingNext = nextProps.editingId === nextProps.messageId;
  if (isEditingPrev !== isEditingNext) {
    return false;
  }

  const prevMessage = prevProps.messages.find(
    (m) => m._id === prevProps.messageId
  );
  const nextMessage = nextProps.messages.find(
    (m) => m._id === nextProps.messageId
  );

  if (prevMessage !== nextMessage) {
    return false; // メッセージオブジェクトが異なる場合は再レンダリング
  }

  return true;
};

export default memo(MessageBubble, areEqual);
