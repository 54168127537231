// ChatInterface.js
import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import MessageList from "./MessageList";
import InputArea from "./InputArea";
import { Outlet } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../AppContext";

function ChatInterface({ authToken }) {
  const chatContext = useOutletContext();
  const { error, setError, aiModels } = chatContext;
  const { activeChatId } = useContext(AppContext);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (error) {
      setShowError(true);
      const timer = setTimeout(() => {
        setShowError(false);
        setTimeout(() => setError(null), 300);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error, setError]);

  return (
    <div className="flex flex-col flex-1 min-h-0 overflow-hidden">
      {/* Error Message Display */}
      {error && (
        <div
          className={`transition-opacity duration-300 ease-in-out ${
            showError ? "opacity-100" : "opacity-0"
          }`}
        >
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-2 mx-auto max-w-full md:max-w-2xl"
            role="alert"
          >
            <span className="block sm:inline">{error}</span>
          </div>
        </div>
      )}
      {/* Message List */}
      <MessageList
        {...chatContext}
        activeChatId={activeChatId}
        aiModels={aiModels}
      />
      {/* Input Area */}
      <InputArea {...chatContext} aiModels={aiModels} />
      {/* Outlet */}
      <Outlet context={{ ...chatContext, authToken }} />
    </div>
  );
}

export default ChatInterface;
