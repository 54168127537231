// InputArea.js

import React, { useState, useRef, useEffect, memo, useCallback } from "react";
import {
  ArrowUpIcon,
  StopIcon,
  PaperClipIcon,
  XCircleIcon,
  MicrophoneIcon,
} from "@heroicons/react/24/solid";
import axios from "axios";
import ImageWithPopup from "./ImageWithPopup";
import modelIcons from "../../assets/modelIcons";

const API_URL = process.env.REACT_APP_API_URL;
const BASE_URL = API_URL.replace("/api", "");

const allModelOptions = [
  { value: "claude-3-5-sonnet-20241022", label: "Claude 3.5" },
  { value: "claude-3-5-haiku-20241022", label: "Claude 3.5 Haiku" },
  { value: "gpt-4o-2024-08-06", label: "GPT-4o" },
  { value: "gemini-1.5-pro", label: "Gemini 1.5 Pro" },
  { value: "gemini-1.5-flash", label: "Gemini 1.5 Flash" },
  { value: "o1-preview", label: "OpenAI o1-preview" },
  {
    value: "llama-3.1-sonar-huge-128k-online",
    label: "Perplexity (Llama-3.1)",
  },
  { value: "stable-image-ultra", label: "Stable Image Ultra" },
  { value: "dall-e-3", label: "DALL-E 3" },
];

const InputArea = memo(
  ({
    input,
    handleInputChange,
    handleKeyPress,
    handleSendMessage,
    isResponding,
    cancelResponse,
    handleFileUpload,
    selectedFiles,
    removeFile,
    selectedModel,
    setSelectedModel,
    isDraggingOver,
    setIsDraggingOver,
    setError,
    error,
    isUploading,
    aiModels,
  }) => {
    const [maxInputHeight, setMaxInputHeight] = useState(0);
    const inputRef = useRef(null);
    const [dragCounter, setDragCounter] = useState(0);

    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const [modelOptions, setModelOptions] = useState([]);

    const startRecording = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = async () => {
          const audioBlob = new Blob(audioChunksRef.current, {
            type: "audio/webm",
          });
          const formData = new FormData();
          formData.append("audio", audioBlob, "recording.webm");

          try {
            const response = await axios.post(
              `${API_URL}/transcribe`,
              formData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );
            handleInputChange({
              target: {
                value: `${inputRef.current.value} ${response.data.text}`,
              },
            });
          } catch (error) {
            console.error("Error transcribing audio:", error);
            if (setError) {
              setError(
                "音声の文字起こしに失敗しました。もう一度お試しください。"
              );
            } else {
              console.error(
                "音声の文字起こしに失敗しました。もう一度お試しください。"
              );
            }
          }

          audioChunksRef.current = [];
        };

        mediaRecorderRef.current.start();
        setIsRecording(true);
      } catch (error) {
        console.error("Error accessing microphone:", error);
        if (setError) {
          setError(
            "マイクへのアクセスに失敗しました。ブラウザの設定を確認してください。"
          );
        } else {
          console.error(
            "マイクへのアクセスに失敗しました。ブラウザの設定を確認してください。"
          );
        }
      }
    };

    const stopRecording = () => {
      if (mediaRecorderRef.current && isRecording) {
        mediaRecorderRef.current.stop();
        setIsRecording(false);
        // マイクストリームを停止
        mediaRecorderRef.current.stream
          .getTracks()
          .forEach((track) => track.stop());
      }
    };

    useEffect(() => {
      const updateMaxHeight = () => {
        setMaxInputHeight(window.innerHeight * 0.4);
      };

      updateMaxHeight();
      window.addEventListener("resize", updateMaxHeight);

      return () => window.removeEventListener("resize", updateMaxHeight);
    }, []);

    const adjustTextareaHeight = useCallback(() => {
      if (inputRef.current) {
        inputRef.current.style.height = "auto";
        const computedStyles = window.getComputedStyle(inputRef.current);
        const minHeight = parseFloat(computedStyles.minHeight);
        const maxHeight = parseFloat(computedStyles.maxHeight);
        let newHeight = inputRef.current.scrollHeight;

        if (newHeight < minHeight) {
          newHeight = minHeight;
        } else if (newHeight > maxHeight) {
          newHeight = maxHeight;
          inputRef.current.style.overflowY = "auto";
        } else {
          inputRef.current.style.overflowY = "hidden";
        }
        inputRef.current.style.height = `${newHeight}px`;
      }
    }, []);

    useEffect(() => {
      adjustTextareaHeight();
    }, [input, adjustTextareaHeight]);

    useEffect(() => {
      setTimeout(() => {
        adjustTextareaHeight();
      }, 0);
    }, [adjustTextareaHeight]);

    const expandableDivStyle = {
      overflow: "hidden",
      transition: "max-height 0.3s ease-in-out, padding 0.3s ease-in-out",
    };

    const handleDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragCounter((prev) => prev + 1);
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setIsDraggingOver(true);
      }
    };

    const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragCounter((prev) => prev - 1);
      if (dragCounter === 1) {
        setIsDraggingOver(false);
      }
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = async (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDraggingOver(false);
      setDragCounter(0);

      const files = Array.from(e.dataTransfer.files);
      handleFileUpload(files);
    };

    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "ArrowDown") {
        e.preventDefault();
        if (inputRef.current) {
          const textarea = inputRef.current;
          textarea.selectionStart = textarea.value.length;
          textarea.selectionEnd = textarea.value.length;
          textarea.scrollTop = textarea.scrollHeight;
          textarea.scrollIntoView({ behavior: "smooth" });
        }
      } else if ((e.ctrlKey || e.metaKey) && e.key === "ArrowUp") {
        e.preventDefault();
        if (inputRef.current) {
          const textarea = inputRef.current;
          textarea.selectionStart = 0;
          textarea.selectionEnd = 0;
          textarea.scrollTop = 0;
          textarea.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    useEffect(() => {
      const options = allModelOptions.filter((model) =>
        aiModels.includes(model.value)
      );
      setModelOptions(options);
    }, [aiModels]);

    useEffect(() => {
      axios
        .get("/user/profile")
        .then((res) => {
          const userAIModels = res.data.aiModels || [];
          const options = allModelOptions.filter((model) =>
            userAIModels.includes(model.value)
          );
          setModelOptions(options);
        })
        .catch((err) => {
          console.error("Error fetching user aiModels:", err);
          setModelOptions(allModelOptions);
        });
    }, []);

    useEffect(() => {
      if (
        modelOptions.length > 0 &&
        !modelOptions.some((model) => model.value === selectedModel)
      ) {
        setSelectedModel(modelOptions[0].value);
      }
    }, [modelOptions, selectedModel, setSelectedModel]);

    const audioExtensions = [
      ".mp3",
      ".mp4",
      ".mpeg",
      ".mpga",
      ".m4a",
      ".wav",
      ".webm",
    ];

    const isAudioFile = (file) => {
      return audioExtensions.some((ext) => file.filename.endsWith(ext));
    };

    const getFileExtension = (file) => {
      return file.filename.split(".").pop().toUpperCase();
    };

    return (
      <div
        className="relative p-2 bg-background pt-0 px-2 pb-2 dark:bg-background-dark flex justify-center"
        style={{ paddingBottom: "calc(env(safe-area-inset-bottom) + 8px)" }}
      >
        <div
          className="flex flex-col w-full max-w-full md:max-w-2xl mx-auto rounded-xl bg-[#f7f7f7] dark:bg-inputBackground-dark shadow-sm"
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onDragLeave={handleDragLeave}
        >
          {/* ドラッグ＆ドロップエリア */}
          <div
            className="bg-messageUser dark:bg-messageUser-dark"
            style={{
              ...expandableDivStyle,
              maxHeight:
                isDraggingOver || isUploading || selectedFiles.length > 0
                  ? "150px"
                  : "0px",
              padding:
                isDraggingOver || isUploading || selectedFiles.length > 0
                  ? "0.5rem"
                  : "0rem",
            }}
          >
            <div className="flex flex-col p-2 border-2 border-dashed rounded-lg">
              {/* ファイル表示エリア */}
              {isUploading ? (
                <p className="text-blue-600 font-semibold">
                  ファイルをアップロードしています...
                </p>
              ) : selectedFiles.length > 0 ? (
                <div className="flex flex-wrap">
                  {selectedFiles.map((file, index) => (
                    <div key={index} className="relative m-1">
                      {file.mimetype === "application/msword" ? (
                        <div className="w-20 h-20 bg-indigo-100 flex items-center justify-center rounded">
                          <span className="text-indigo-500 font-bold">DOC</span>
                        </div>
                      ) : file.mimetype ===
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                        <div className="w-20 h-20 bg-blue-100 flex items-center justify-center rounded">
                          <span className="text-blue-500 font-bold">DOCX</span>
                        </div>
                      ) : file.mimetype ===
                        "application/vnd.openxmlformats-officedocument.presentationml.presentation" ? (
                        <div className="w-20 h-20 bg-orange-100 flex items-center justify-center rounded">
                          <span className="text-orange-500 font-bold">
                            PPTX
                          </span>
                        </div>
                      ) : file.mimetype === "application/pdf" ? (
                        <div className="w-20 h-20 bg-red-100 flex items-center justify-center rounded">
                          <span className="text-red-500 font-bold">PDF</span>
                        </div>
                      ) : file.mimetype === "text/csv" ? (
                        <div className="w-20 h-20 bg-green-100 flex items-center justify-center rounded">
                          <span className="text-green-500 font-bold">CSV</span>
                        </div>
                      ) : isAudioFile(file) ? (
                        <div className="w-20 h-20 bg-blue-100 flex items-center justify-center rounded">
                          <span className="text-blue-500 font-bold">
                            {getFileExtension(file)}
                          </span>
                        </div>
                      ) : (
                        <ImageWithPopup
                          src={`${BASE_URL}${file.filepath}`}
                          alt={`Uploaded ${index}`}
                        />
                      )}
                      <button
                        onClick={() => removeFile(index)}
                        className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors duration-200"
                      >
                        <XCircleIcon className="h-4 w-4" />
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-blue-600 font-semibold">
                  + ドロップしてファイルを追加
                </p>
              )}
            </div>
          </div>
          {/* 入力エリア */}
          <div className="flex flex-col rounded-xl overflow-hidden">
            {/* テキストエリア */}
            <textarea
              ref={inputRef}
              className="flex-grow mr-2 pt-3 px-3 pb-0 rounded mb-0 w-full resize-none bg-messageUser dark:bg-messageUser-dark text-textPrimary dark:text-textPrimary-dark focus:outline-none text-base"
              style={{
                maxHeight: `${maxInputHeight}px`,
                minHeight: "63px",
                height: "63px",
                lineHeight: "24px",
              }}
              value={input}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              onKeyDown={handleKeyDown}
              placeholder="メッセージを入力...(Shift + Enterで改行)"
            />
            {/* アイコン群 */}
            <div className="flex justify-between items-center bg-messageUser dark:bg-messageUser-dark py-2 px-2">
              {/* 左側のアイコン */}
              <div className="flex items-center">
                {/* モデル選択アイコン */}
                <div className="flex space-x-1 ml-2 overflow-x-auto flex-nowrap">
                  {modelOptions.map((model) => (
                    <button
                      key={model.value}
                      onClick={() => setSelectedModel(model.value)}
                      title={model.label}
                      className={`rounded-xl border p-2 flex-shrink-0 flex items-center justify-center transition-all duration-200
                    ${
                      selectedModel === model.value
                        ? "border-modelButtonBorder-light dark:border-modelButtonBorder-dark bg-modelButtonSelected-light dark:bg-modelButtonSelected-dark"
                        : "border-transparent"
                    }
                    hover:bg-modelButtonHover-light dark:hover:bg-modelButtonHover-dark`}
                    >
                      <img
                        src={modelIcons[model.value]}
                        alt={model.label}
                        className="h-6 w-6 object-contain"
                      />
                    </button>
                  ))}
                </div>
              </div>
              {/* 右側のアイコン */}
              <div className="flex items-center flex-shrink-0">
                {/* ファイル（クリップ）アイコン */}
                <label className="flex items-center justify-center cursor-pointer p-2 mr-2">
                  <input
                    type="file"
                    accept="image/*,.pdf,.csv,audio/*,.doc,.docx,.pptx"
                    onChange={(event) =>
                      handleFileUpload(Array.from(event.target.files))
                    }
                    multiple
                    className="hidden"
                  />
                  <PaperClipIcon className="h-5 w-5 text-gray-700 dark:text-gray-300" />
                </label>
                {/* マイクボタン */}
                <button
                  onClick={isRecording ? stopRecording : startRecording}
                  className="flex items-center justify-center p-2 mr-2"
                >
                  <MicrophoneIcon className="h-5 w-5 text-gray-700 dark:text-gray-300" />
                </button>
                {/* 送信ボタン */}
                {isResponding ? (
                  <button
                    className="bg-gray-700 text-white flex items-center justify-center p-2 mr-2 rounded-full hover:bg-gray-600 transition-colors duration-200"
                    onClick={cancelResponse}
                  >
                    <StopIcon className="h-5 w-5" />
                  </button>
                ) : (
                  <button
                    className={`flex items-center justify-center p-2 mr-2 rounded-full transition-colors duration-200 ${
                      input.trim()
                        ? "bg-sendButtonActiveLight text-white dark:bg-sendButtonActiveDark dark:text-black"
                        : "bg-sendButtonInactiveLight text-black dark:bg-sendButtonInactiveDark dark:text-white cursor-not-allowed"
                    }`}
                    onClick={handleSendMessage}
                    disabled={!input.trim()}
                  >
                    <ArrowUpIcon className="h-5 w-5" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default InputArea;
