// MessageList.js

import React, { memo, useRef, useEffect, useState } from "react";
import MessageBubble from "./MessageBubble";
import isEqual from "lodash.isequal";

const MessageList = memo(
  ({
    messages,
    activeMessageIds,
    messageVersionState,
    setMessageVersionState,
    editingId,
    setEditingId,
    updateMessage,
    isResponding,
    setError,
    retryMessage,
    setActiveMessageId,
    navigateSiblingAtDepth,
    selectedModel,
    setSelectedModel,
    activeChatId,
    aiModels,
  }) => {
    const messagesContainerRef = useRef(null);
    const prevMessagesLengthRef = useRef(messages.length);
    const [isUserAtBottom, setIsUserAtBottom] = useState(true);
    const previousScrollTopRef = useRef(0);

    // メッセージ追加時に一番下にスクロール
    useEffect(() => {
      if (
        messagesContainerRef.current &&
        messages.length > prevMessagesLengthRef.current
      ) {
        messagesContainerRef.current.scrollTo({
          top: messagesContainerRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
      prevMessagesLengthRef.current = messages.length;
    }, [messages]);

    // activeChatIdの変更時にスクロール位置をトップにリセット
    useEffect(() => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTo({
          top: 0,
          behavior: "auto",
        });
      }
    }, [activeChatId]);

    // ユーザーのスクロール位置を監視
    useEffect(() => {
      const container = messagesContainerRef.current;
      if (!container) return;

      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = container;

        // ユーザーが最下部にいるかどうかを判定
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 20;
        setIsUserAtBottom(isAtBottom);

        previousScrollTopRef.current = scrollTop;
      };

      container.addEventListener("scroll", handleScroll);

      // クリーンアップ
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }, []);

    const renderMessages = () => {
      const messageChain = activeMessageIds
        .map((id) => messages.find((m) => m._id === id))
        .filter(Boolean);

      return messageChain.map((message, index) => (
        <MessageBubble
          key={message._id}
          messageId={message._id}
          messages={messages}
          messageVersionState={messageVersionState}
          setMessageVersionState={setMessageVersionState}
          editingId={editingId}
          setEditingId={setEditingId}
          updateMessage={updateMessage}
          isResponding={isResponding}
          setError={setError}
          retryMessage={retryMessage}
          setActiveMessageId={setActiveMessageId}
          depth={index}
          navigateSiblingAtDepth={navigateSiblingAtDepth}
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
          messagesContainerRef={messagesContainerRef}
          isLastMessage={index === messageChain.length - 1}
          isUserAtBottom={isUserAtBottom}
          aiModels={aiModels}
        />
      ));
    };

    return (
      <div
        className="flex-grow overflow-auto p-2 bg-background dark:bg-background-dark text-textPrimary dark:text-white w-full min-w-0 min-h-0"
        ref={messagesContainerRef}
      >
        <div className="w-full max-w-full md:max-w-2xl mx-auto overflow-x-auto">
          {renderMessages()}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      isEqual(prevProps.activeMessageIds, nextProps.activeMessageIds) &&
      isEqual(prevProps.messages, nextProps.messages) &&
      prevProps.isResponding === nextProps.isResponding &&
      prevProps.editingId === nextProps.editingId &&
      isEqual(prevProps.messageVersionState, nextProps.messageVersionState)
    );
  }
);

export default MessageList;
